import React, { useState, useEffect } from "react";
import Select from "react-select";
const selectStyles = {
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
  }),
  control: (styles) => ({
    ...styles,
    borderRadius: 0,
    //border: "1px solid black",
    // none of react-select's styles are passed to <Control />
  }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
function handleSelectChange(location) {
  console.log("location", location);
  window.location.replace(location.link); // Simulate HTTP redirect
}
export default function LocationList({ locationList }) {
  return (
    <div>
      <Select
        styles={selectStyles}
        name="what-boutique-select"
        options={locationList}
        onChange={handleSelectChange}
        //defaultValue={this.props.locationSelectOptions[2]}
        /* value={this.props.locationSelectOptions.find(
          ({ value }) => this.props.digitalgiftcard.centerId === value
        )} */
        placeholder="Location"
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          boxShadow:
            "0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1)",
          border: "none",
          // border: "3px solid black",
          outline: "none",
          colors: {
            ...theme.colors,
            primary: "#000",
            primary25: "#cccccc",
          },
        })}
      />
    </div>
  );
}
