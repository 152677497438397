//import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import LocationList from "./components/locations";
import { apiUrl } from "./components/config";
function App() {
  const [locationList, setLocationList] = useState([]);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    axios
      .get(
        `${apiUrl}/wp-json/wp/v2/nowfranchise?per_page=100&time=${Date.now()}`
      )
      .then((response) => {
        console.log(response);

        let locations = [];
        const data = response.data;
        /* let locationsArray = data.map((obj) => {
          let rObj = {};
          rObj["id"] = obj.acf.as_franchiseZenotiID;
          rObj["name"] = obj.acf.as_franchiseName;
          rObj["address"] = obj.acf.as_franchiseStreetAddress;
          rObj["city"] = obj.acf.as_franchiseCity;
          rObj["state"] = obj.acf.as_franchiseState;
          rObj["zip"] = obj.acf.as_franchisePostalCode;
          rObj["services"] = obj.acf.as_franchiseAvailableServices;
          rObj["phone"] = obj.acf.as_franchisePhone;
          rObj["lat"] = obj.acf.as_franchiseLatitude;
          rObj["lng"] = obj.acf.as_franchiseLongitude;
          return rObj;
        });
        console.log(locationsArray);
        locationsArray.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }); */
        let selectOptions = data.filter((obj) =>
          obj.acf.as_franchiseAvailableServices.find(
            ({ value }) => value === "giftcards"
          )
        );
        selectOptions = selectOptions.map((obj) => {
          let rObj = {};
          rObj["name"] = obj.acf.as_franchiseName;
          rObj["value"] = obj.acf.as_franchiseZenotiID;
          rObj["link"] = obj.link.replace("/nowfranchise", "");
          rObj["label"] = `${obj.acf.as_franchiseName} | ${
            obj.acf.as_franchiseCity.toLowerCase() !=
            obj.acf.as_franchiseName.toLowerCase()
              ? obj.acf.as_franchiseCity + ", " + obj.acf.as_franchiseState
              : obj.acf.as_franchiseState
          }`; /* 
          rObj[
            "label"
          ] = `${obj.acf.as_franchiseName} (${obj.acf.as_franchiseCity}, ${obj.acf.as_franchiseState})`; */
          /* if (
            obj.acf.as_franchiseAvailableServices.find(
              ({ value }) => value === "booking"
            )
          ) */
          return rObj;
        });
        console.log("locationlist", selectOptions);
        selectOptions.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        });
        setLocationList(selectOptions);
        setReady(true);
        // this.setState({ locationSelectOptions: selectOptions });
        //this.setState({ locations: locationsArray });
        //this.setState({ step_loading: false });
      });
  }, []);
  return (
    <div className="locationListApp">
      <ReactPlaceholder type="text" rows={1} ready={ready}>
        <LocationList locationList={locationList} />
      </ReactPlaceholder>
    </div>
  );
}

export default App;
